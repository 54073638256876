import { useState } from 'react';
import { fetchData } from '../helpers/http-utils';
import { AuthUser, UserProfile } from '../models/user';

const KEY = 'tmpx-api-token';

export type Credentials = { email: string, password: string };

export function isLoggedIn(): boolean {
    const token = localStorage.getItem(KEY);
    return typeof token === 'string';
}

export function requireToken(): string | never {
    const token = localStorage.getItem(KEY);
    if (token) {
        return token;
    }
    
    throw new Error("Not logged in");
}

export function deleteCredentials() {
    localStorage.removeItem(KEY);
}

const useAuth = () =>  {
    const [token, setToken] = useState<string | null>(localStorage.getItem(KEY));
    // const [user, setUser] = useState<{ name: string }>();

    const login = async (credentials: Credentials) => {
        const { data, statusCode } = await fetchData({ url: '/auth/login', method: 'POST', body: credentials });
        
        if (statusCode === 200 && data.accessToken) {
            console.log('Successfully logged in', data.user);
            localStorage.setItem(KEY, data.accessToken);
            setToken(data.accessToken);
            return data.user as AuthUser;
        }

        return null;
    };

    const logout = () => {
        deleteCredentials();
        setToken(null);
    }

    const profile = async () => {
        const { data } = await fetchData({ url: '/auth/profile', token: requireToken() });
        return data.user as UserProfile;
    };

    return {
        token,
        login,
        logout,
        profile,
    };
}

export default useAuth;